import React from 'react'
import { graphql, useStaticQuery, Link} from 'gatsby'
import {ListGroup}  from 'react-bootstrap';

export default function LeftNavASPNETTutorial() {
    const data = useStaticQuery(graphql`
    query aspnetTutorial {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {glob: "**/examples/aspnet/*"}, 
          frontmatter: {featured: {eq: 1}}}
        sort: {order: DESC, fields: frontmatter___published}
      ) {
        nodes {
          frontmatter {
            slug
            title
          }
          id
        }
      }
    }
  `)
    
    const menu_items = data.allMarkdownRemark.nodes

    return (
        <ListGroup>
            {menu_items.map(item => (
                <ListGroup.Item key={item.id}>
                 <Link activeStyle={{ color: "red" }} to={"/" + item.frontmatter.slug} key={item.id}>
                    {item.frontmatter.title}
                </Link>  
                </ListGroup.Item>
            ))}
        </ListGroup>
    )
}
